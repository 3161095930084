import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Paper from '@mui/material/Paper';
import { InputAdornment,Typography,Container,TextField,Button, MenuItem, FormControl, Tooltip } from '@mui/material';
import LoadingSpinner from 'src/components/Spinner';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import Iconify from '../components/Iconify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {uploadFileToFirebase,returnFileName,deleteImageFromFirebase,splitString, convertDateAndTime} from "../global/globalFunctions"
import noImage from '../assests/No_image.svg'
import palette from '../theme/palette';
import ConfimModal from "../global/Modals/ConfimModal"
import CustomizedSnackbars from '../global/Snackbar/CustomSnackbar';
import { useNavigate,useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import imageImport from 'src/utils/imageImport';
import { convertDate,copyContentFunc } from '../global/globalFunctions';
import { editable_config } from 'src/editable_config';
import searchNotFound from "../assests/searchnotfound.gif"
import EditAnnouncementModal from 'src/global/Modals/EditAnnouncementModal';
import ConfirmModal from ".././global/Modals/ConfimModal"
import NotifyModal from 'src/global/Modals/NotifyModal';


function Announcements({handleClose,userId}) {
    const [ render, setRender ] = useState(false);
    const [ loading, setLoading] = useState(false)
  const [snackbarOpen,setSnackbarOpen ] = useState(false)
  const [openConfimModal ,setOpenConfimModal] = useState(false);
  const [ announcementData, setAnnouncementData ] = useState({title:'',image_url:'',readmore_link:'',message:''})
  const [ announcement , setAnnouncements ] = useState([])
  const [message ,setMessage] = useState({type:"",message:""})
  const [ addLogoFileUpload , setaddLogoFileUpload ] = useState()
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [ ordersCount ,setOrdersCount ] =useState(0);
  const [ shippedProductsCount ,setShippedProductsCount ] =useState(0);
  const [ orderDetail, setOrderDetail ] = useState({});
  const [ orderStatus , setOrderStatus ] = useState([]);
  const [openEditAnnouncementModal, setOpenEditAnnouncementModal] = React.useState({status:false,selected_announcement:{}});
  const [openNotifyModal, setOpenNotifyModal] = React.useState(false);

  
  // const search = useLocation();
  const navigate = useNavigate()

  console.log("USER DATA",announcementData)

  //##################### GET USER BY APP ID #####################
  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/all/app/announcements/for/admin`, {headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log(res?.data);
      setAnnouncements([...res?.data?.result]);

    })
    .catch(err=>{
      console.log(err)
    })
  },[render])
  //##################### GET USER APP ID #####################

  // #####################  handle Change #####################
  const handleSubmitUserDetails=async(e)=>{
    e.preventDefault()
    setLoading(true);
   await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/create/a/new/app/announcement`,{...announcementData}, {headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log(res?.data)
      toast.success("Announcement Created!!")
      setAnnouncementData((prev=>({
        title:'',
        readmore_link:'',
        message:'',
        image_url:'',
      })))
      setRender(prev=>!prev);
      setLoading(false);
    })
    .catch(err=>{
      console.log(err)
      setLoading(false);
      toast.error("Something Went Wrong!!")
    })

  }
  // #####################  handle Change #####################

   // ##################### handle Delete Announcemet  #####################

   async function handleDeleteAnnouncement(announ_id){
    await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/delete/app/announcement/by/id/${announ_id}`, {headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log(res?.data)
      toast.success("Announcement Deleted!!")
      setRender(prev=>!prev);
      setLoading(false);
    })
    .catch(err=>{
      console.log(err)
      setLoading(false);
      toast.error("Something Went Wrong!!")
    })


   }

   // ##################### handle Delete Announcemet  #####################
 

  // ##################### SNACK BAR FUNCTIONs ##################
const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarOpen(false);
  };
  // ##################### SNACK BAR FUNCTIONs ##################




          function createData(name, calories, fat, carbs, protein,amount,status) {
            return {
              name,
              calories,
              fat,
              carbs,
              protein,
              amount,
              status
            };
          }
          

        
        function descendingComparator(a, b, orderBy) {
            if (b[orderBy] < a[orderBy]) {
              return -1;
            }
            if (b[orderBy] > a[orderBy]) {
              return 1;
            }
            return 0;
          }
                                                                                                        
          function getComparator(order, orderBy) {
            return order === 'desc'
              ? (a, b) => descendingComparator(a, b, orderBy)
              : (a, b) => -descendingComparator(a, b, orderBy);
          }
          
          // This method is created for cross-browser compatibility, if you don't
          // need to support IE11, you can use Array.prototype.sort() directly
          function stableSort(array, comparator) {
            const stabilizedThis = array?.map((el, index) => [el, index]);
            stabilizedThis?.sort((a, b) => {
              const order = comparator(a[0], b[0]);
              if (order !== 0) {
                return order;
              }
              return a[1] - b[1];
            });
            return stabilizedThis?.map((el) => el[0]);
          }
          
          const headCells = [
            
        
            {
              id: 'image_uri',
              numeric: false,
              disablePadding: true,
              label: 'Image',
            },
            {
              id: 'Title',
              numeric: true,
              disablePadding: false,
              label: 'Title',
            },
          
            {
              id: 'Message',
              numeric: true,
              disablePadding: false,
              label: 'Message',
            },
            {
              id: 'Read More',
              numeric: true,
              disablePadding: false,
              label: 'Read More',
            },
            {
              id: 'Action',
              numeric: true,
              disablePadding: false,
              label: 'Action',
            },
           
          ];
          
          
          function EnhancedTableHead(props) {
            const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, } =
              props;
            const createSortHandler = (property) => (event) => {
              onRequestSort(event, property);
            };
          
            return (
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    {/* <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    /> */}
                    #
                  </TableCell>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'center' : 'left'}
                      padding={headCell.disablePadding ? 'none' : 'none'}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={{textTransform:"uppercase",fontSize:12}}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            );
          }
          
          EnhancedTableHead.propTypes = {
            numSelected: PropTypes.number.isRequired,
            onRequestSort: PropTypes.func.isRequired,
            onSelectAllClick: PropTypes.func.isRequired,
            order: PropTypes.oneOf(['asc', 'desc']).isRequired,
            orderBy: PropTypes.string.isRequired,
            rowCount: PropTypes.number.isRequired,
          };


          const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
          };
        
          const handleSelectAllClick = (event) => {
            if (event.target.checked) {
              const newSelecteds = orderDetail?.products?.map((n,) => n?._id);
              setSelected(newSelecteds);
              return;
            }
            setSelected([]);
          };
        
          const handleClick = (event, name) => {
            const selectedIndex = selected.indexOf(name);
            let newSelected = [];
        
            if (selectedIndex === -1) {
              newSelected = newSelected.concat(selected, name);
            } else if (selectedIndex === 0) {
              newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
              newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
              newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
              );
            }
        
            setSelected(newSelected);
          };

          const isSelected = (name) => selected.indexOf(name) !== -1;
          const handleChangePage = (event, newPage) => {
            setPage(newPage);
          };
        
          const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          };
        
  const handleCloseNotifyModal = ()=>{
    setOpenNotifyModal(false)
    // setRender(prev=>!prev)
  }



  return (
    <>
    {/* =========== LOADING SPINNER ============ */}    
    <LoadingSpinner loading={loading} />
    {/* =========== LOADING SPINNER ============ */}
      {/* #################### SANCKBAR MESSAGE ######################## */}
       <CustomizedSnackbars onOpen={snackbarOpen} type={message?.type} handleClose={handleCloseSnackbar}  message={message?.message} />
 {/* #################### SANCKBAR MESSAGE ######################## */}

 {/* #################### ConfirmModal POPUP ######################## */}
 {/* <ConfirmModal open={openConfimModal} title='Delete Announcement' message='Are you sure you want to delete?' onYes={handleDeleteAnnouncement} handleClose={()=>setOpenConfimModal(false)}  /> */}
 {/* #################### ConfirmModal POPUP ######################## */}


 {/* #################### EDIT ANNOUNCEMENT POPUP ######################## */}
 <EditAnnouncementModal open={openEditAnnouncementModal.status} announcement_data={openEditAnnouncementModal.selected_announcement}  close={()=>{setOpenEditAnnouncementModal((prev)=>({...prev,status:false,selected_announcement:{}}));setRender(prev=>!prev)}}  />
 {/* #################### EDIT ANNOUNCEMENT POPUP ######################## */}


   <div className='close_edit_Category ' >
    {/* <HighlightOffIcon style={{color:palette.primary.main}}  fontSize='large' /> */}
    {/* <HighlightOffIcon style={{color:palette.primary.main}}  fontSize='large' /> */}
</div>
 {/* #################### EDIT ANNOUNCEMENT POPUP ######################## */}
 <NotifyModal open={openNotifyModal}  handleclose={()=>setOpenNotifyModal(false)} />
 {/* #################### EDIT ANNOUNCEMENT POPUP ######################## */}

<Paper elevation={4} >
    <div className='edit-user-main-box' >
    <div className='' style={{display:'flex',justifyContent:'space-between',alignItems:'center'}} >
      <div className='inner-page-heading-box' >
      <IconButton sx={{color:'black'}}  onClick={()=>navigate(-1)} >
        <Iconify icon="material-symbols:arrow-back-rounded" />
        </IconButton>
     <div>
     <h2>{`App Announcements(${announcement?.length}) `}</h2>
     <p>  View & edit app announcements necessary information from here</p>
      </div>
     </div>
     <Button  onClick={()=>setOpenNotifyModal(true)}   variant='outlined'  style={{padding:"6px 30px"}} startIcon={<Iconify icon="heroicons-outline:speakerphone" />} > Notify Users </Button>
      </div>


    <div className='user-details-inner-box' >
    <form onSubmit={handleSubmitUserDetails}>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">Accouncement Title  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="title"  onChange={(e)=>setAnnouncementData(prev=>({...prev,title:e.target.value}))} value={announcementData?.title} placeholder="Enter Announcement Title " variant="outlined" />
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">Accouncement Image Uri  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="image_url"  onChange={(e)=>setAnnouncementData(prev=>({...prev,image_url:e.target.value}))} value={announcementData?.image_url} placeholder="Enter Image URI " variant="outlined" />
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">Read More Link  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="readmore_link"  onChange={(e)=>setAnnouncementData(prev=>({...prev,readmore_link:e.target.value}))} value={announcementData?.readmore_link} placeholder="Enter Read More Link " variant="outlined" />
                    </div>
                    <div className='add_product_label_input'>
                    <label htmlFor=""> Accouncement Message  </label>
                    <TextField multiline rows={4}  fullWidth className='product_form_input' name='message' onChange={(e)=>setAnnouncementData(prev=>({...prev,message:e.target.value}))} value={announcementData?.message} id="outlined-basic" placeholder="Enter Announcement Message " variant="outlined" />
                    </div>
                    <div style={{paddingTop:20}} >

<Button onClick={()=>navigate(-1)}  variant='text' style={{marginRight:"10px"}}  startIcon={<Iconify icon="material-symbols:line-start-arrow-notch-rounded" />} >Back  </Button>

 <Button   variant='contained' type='submit' style={{padding:"6px 30px"}} startIcon={<Iconify icon="material-symbols:check-circle" />} > Save Changes </Button>
</div>
</form>
      
<div className='user-profile-detail-box' >
<TableContainer>
          <Table
            sx={{ minWidth: 800 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              
              rowCount={orderDetail?.products?.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(announcement, getComparator(order, orderBy))
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  const isItemSelected = isSelected(row?._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row?._id}
                      selected={isItemSelected}

                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, row._id)}

                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        /> */}#
                      </TableCell>
                               <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="2"
                         style={{textTransform:'capitalize',display:'flex',alignItems:'center',gap:'5px'}} 
                      >
                        <img className='banner-table-image' alt="product" src={row?.image_url } />

                      </TableCell>
                      <TableCell align="center">{row?.title?.slice(0,24)}...</TableCell>
                      <TableCell align="center">{row?.message?.slice(0,24)}...</TableCell>
                      <TableCell align="center"  ><a href={row?.readmore_link} target='_blank' ><Iconify icon='ph:link-bold'  width={22} height={22} /></a></TableCell>
                       <TableCell align="center">
                        <Tooltip title='Edit' >
                        <IconButton onClick={()=>setOpenEditAnnouncementModal(prev=>({...prev,status:true,selected_announcement:row}))} >
                        <Iconify icon='tabler:edit' width={22} height={22} />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title='Delete' >
                        <IconButton onClick={()=>handleDeleteAnnouncement(row?._id)} >
                        <Iconify icon='gg:trash' width={22} height={22} />
                        </IconButton>
                        </Tooltip>
                        </TableCell>
                      
                    </TableRow>
                  );
                })}
                {/* {!orderDetail?.products?.length >0 &&   <TableCell colSpan={9}> <div className='search-not-found' >
                  <img className='search-not-found-img' src={searchNotFound} alt="searchNotFound" />
                  <Typography
          
          variant="h6"
          id="tableTitle"
          component="div"
        >
          No Announcement Found!!
        </Typography>
                </div> </TableCell> } */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          // rowsPerPageOptions={[5, 10, 25]}
          rowsPerPageOptions={[5]}
          component="div"
          count={announcement?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

    
      </div>
    

    </div>
  

   </div>
   </Paper>

   </>
  )
}

export default Announcements