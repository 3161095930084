import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton, TextField,FormControl,MenuItem } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { editable_config } from 'src/editable_config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { UseContextState } from '../GlobalContext/GlobalContext';


export default function NotifyModal(props) {
  const [ notifyData, setNotifyData ] = React.useState({display_status:false})
  const [ render, setRender ] = React.useState(false)
  const {authState} = UseContextState()
  console.log("notifyData.notifyData.notifyData.notifyData",notifyData)

  React.useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/dashboard/notify`,{headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      // console.log(res.data);
      setNotifyData(res?.data?.dashboard_notify[0]?.dashboard_notify)
    })
    .catch(err=>{
      console.log(err)
    })
  },[])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius:"10px 10px ",
    boxShadow: 24,
    p: 4,
  };

  const handleNotifyUserDetails = async (e)=>{
    e.preventDefault()
    await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/edit/dashboard/notify/for/admin/${authState?.user?._id}`,{...notifyData}, {headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      // console.log(res?.data)
      toast.success("Changes Saved!!")
    })
    .catch(err=>{
      console.log(err)
      toast.error("Something Went Wrong!!")
    })

  }
 

  return (
    <div>
      
      <Modal
        open={props.open}
        onClose={props.handleclose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
       
      >
          <Box sx={style}>
          <IconButton onClick={props.handleclose} style={{color:'text.secondary'}} className='close-upgrade-icon' >
          <Iconify icon="material-symbols:close" />
          </IconButton>
          <div>
     <h2>Notify Users</h2>
     <p>  Notify users for new update from here</p>
     </div>
              <div>
              <form onSubmit={handleNotifyUserDetails} >
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">Notify Title  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="content"  onChange={(e)=>setNotifyData(prev=>({...prev,content:e.target.value}))} value={notifyData?.content} placeholder="Enter Notify Content " variant="outlined" />
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">Read More Link  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="knowmore_link"  onChange={(e)=>setNotifyData(prev=>({...prev,knowmore_link:e.target.value}))} value={notifyData?.knowmore_link} placeholder="Enter Knowmore Link " variant="outlined" />
                    </div>
                    <div className='add_product_label_input'>
                    <label htmlFor="">Notify Status  </label>
                    <FormControl fullWidth  >
                        <TextField
                          labelId="demo-select-small"
                          id="demo-select-small"
                          name="display_status"
                          select
                          value={notifyData?.display_status}
                          onChange={(e)=>setNotifyData(prev=>({...prev,display_status:e.target.value}))}
                          >
                          <MenuItem value={true}>Active</MenuItem>
                          <MenuItem value={false}>Not Active</MenuItem>
                        </TextField>
                        </FormControl>
                       </div>
                    <div style={{paddingTop:20}} >

<Button onClick={props.handleclose}  variant='text' style={{marginRight:"10px"}}   >Discard  </Button>

 <Button   variant='contained' type='submit' style={{padding:"6px 30px"}} startIcon={<Iconify icon="material-symbols:check-circle" />} > Save  </Button>
</div>
</form>
      
              </div>
          </Box>
      </Modal>
    </div>
  );
}