import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Paper from '@mui/material/Paper';
import { InputAdornment,Container,TextField,Button, MenuItem, FormControl } from '@mui/material';
import LoadingSpinner from 'src/components/Spinner';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from '../../components/Iconify';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import CategoryIcon from '@mui/icons-material/Category';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import {uploadFileToFirebase,returnFileName,deleteImageFromFirebase,splitString, convertDateAndTime} from "../../global/globalFunctions"
import noImage from '../../assests/No_image.svg'
import palette from '../../theme/palette';
import ConfimModal from "../../global/Modals/ConfimModal"
import CustomizedSnackbars from '../../global/Snackbar/CustomSnackbar';
import { useNavigate,useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import imageImport from 'src/utils/imageImport';
import { convertDate,copyContentFunc } from '../../global/globalFunctions';
import { editable_config } from 'src/editable_config';


function EditUserPage({handleClose,userId}) {
    const [ render, setRender ] = useState(false);
    const [ loading, setLoading] = useState(false)
  const [snackbarOpen,setSnackbarOpen ] = useState(false)
  const [openConfimModal ,setOpenConfimModal] = useState(false);
  const [ userData, setUserData ] = useState({app_type:'',app_color:'',app_published:false,app_dashboard_status:false})
  const [message ,setMessage] = useState({type:"",message:""})
  const [ addLogoFileUpload , setaddLogoFileUpload ] = useState()

  
  // const search = useLocation();
  const {app_id}=useParams()
  const navigate = useNavigate()

  console.log("app_id",app_id)
  console.log("USER DATA",userData)



  //##################### GET USER BY APP ID #####################
  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/get/adiogent/by/appid/for/admin/${app_id}`, {headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log(res?.data);
      setUserData(res?.data?.result);

    })
    .catch(err=>{
      console.log(err)
    })
  },[app_id,render])
  //##################### GET USER APP ID #####################

  // #####################  handle Change #####################
  const handleSubmitUserDetails=(e)=>{
    e.preventDefault()
    setLoading(true);
    axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/edit/app/details/for/adiogent/user/by/appid/${userData?.app_id}`,{...userData}, {headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log(res?.data)
      toast.success("Changes Saved Successfully!!")
      setRender(prev=>!prev);
      setLoading(false);
    })
    .catch(err=>{
      console.log(err)
      setLoading(false);
      toast.error("Something Went Wrong!!")
    })

  }
  // #####################  handle Change #####################

   // ##################### handle form submit  #####################

// chnage logo  
const handleFileUpload =async(e)=>{
    // console.log(e)
    if(!e.target.files?.length) return
    if(e.target.files?.length > 1) return alert("You can only select 1 images");
    setLoading(true)
    // console.log(e.target.files[0])
    let allImages = [...e.target.files]
    setaddLogoFileUpload(allImages)
    const logoImageToFirebase= await uploadFileToFirebase(`/${process.env.REACT_APP_IMAGES_FOLDER_NAME}/logo/${userData?.app_id}/`,e.target.files[0])
  //  const bannersToFirebase = await uploadFileToFirebase(`/${process.env.REACT_APP_IMAGES_FOLDER_NAME}/banners/${e.target?.files[0]?.name}/`,e.target.files[0]);
   console.log("IMAGES AFTER FIREBASE",logoImageToFirebase);
    if(logoImageToFirebase.image_url){
      setLoading(false)
      
     await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/change/app/logo/for/adiogent/user/by/appid/${userData?.app_id}`,{app_logo:logoImageToFirebase}, {headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
        .then(res=>{
            console.log(res?.data);
            if(res?.data?.status){
                deleteImageFromFirebase(res?.data?.previous?.path,res?.data?.previous?.image_name)
                // setMessage((prev)=>({...prev,type:"success",message:"Banner Change Successfully !"}))
                // setSnackbarOpen(true)
                toast.success("Logo Changed Successfully!!")
                setRender(prev=>!prev);
                setLoading(false)
            }
        })
        .catch(err=>{
            console.log(err);
            toast.error("Logo Not Changed !!")
            setRender(prev=>!prev);
            setLoading(false)
        })
    }


  }
   // ##################### handle form submit  #####################
 

  // ##################### SNACK BAR FUNCTIONs ##################
const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    setSnackbarOpen(false);
  };
  // ##################### SNACK BAR FUNCTIONs ##################


  
  const store_type =editable_config.storeCategory;


    const availablecolors=[
        'black',
        'silver',
        'gray',
        'white',
        'maroon',
        'red',	
        'purple',	
        'green',	
        'lime',	
        'olive',	
        'yellow',	
        'navy',
        'blue',	
        'teal',		
        'aqua',	
        'orange',	
        'aliceblue',	
        'antiquewhite',	
        'aquamarine',	
        'azure',	
        'beige',	
        'bisque',	
        'blanchedalmond',	
        'blueviolet',	
        'brown',	
        'burlywood',		
        'cadetblue',	
        'chartreuse',		
        'chocolate',	
        'coral',	
        'cornflowerblue',	
        'cornsilk',	
        'crimson',		
        'cyan',	
        'darkblue',	
        'darkcyan',	
        'darkgoldenrod',		
        'darkgray',	
        'darkgreen',		
        'darkkhaki',	
        'darkmagenta',	
        'darkolivegreen',	
        'darkorange',	
        'darkorchid',	
        'darkred',	
        'darksalmon',	
        'darkseagreen',	
        'darkslateblue',		
        'darkslategray',	
        'darkslategrey',	
        'darkturquoise',		
        'darkviolet',	
        'deeppink',	
        'deepskyblue',		
        'dimgray',		
        'dimgrey',		
        'dodgerblue',	
        'firebrick',		
        'floralwhite',		
        'forestgreen',	
        'gainsboro',	
        'ghostwhite',	
        'gold',	
        'goldenrod',		
        'greenyellow',		
        'grey',	
        'honeydew',		
        'hotpink',		
        'indianred',	
        'indigo',		
        'ivory',	
        'khaki',	
        'lavender',	
        'lavenderblush',	
        'lawngreen',	
        'lemonchiffon',	
        'lightblue',		
        'lightcoral',	
        'lightcyan',	
        'lightgray',	
        'lightgreen',		
        'lightpink',	
        'lightsalmon',		
        'lightseagreen',	
        'lightskyblue',	
        'lightsteelblue',	
        'lightyellow',	
        'limegreen',		
        'magenta',	
        'pink',	
        'plum',	
        'powderblue',	
        'rosybrown',		
        'royalblue',	
        'saddlebrown',		
        'salmon',	
        'sandybrown',	
        'seagreen',	
        'seashell',	
        'sienna',	
        'skyblue',	
        'slateblue',	
        'slategray',	
        'slategrey',	
        'snow',	
        'springgreen',	
        'steelblue',	
        'tan',	
        'thistle',	
        'tomato',	
        'turquoise',	
        'violet',	
        'wheat',				
          ]

  return (
    <>
    {/* =========== LOADING SPINNER ============ */}    
    <LoadingSpinner loading={loading} />
    {/* =========== LOADING SPINNER ============ */}
      {/* #################### SANCKBAR MESSAGE ######################## */}
    
       <CustomizedSnackbars onOpen={snackbarOpen} type={message?.type} handleClose={handleCloseSnackbar}  message={message?.message} />
     
 
 {/* #################### SANCKBAR MESSAGE ######################## */}

   <div className='close_edit_Category ' >
    <HighlightOffIcon style={{color:palette.primary.main}} onKeyDown={handleClose}  onClick={handleClose} fontSize='large' />
    {/* <HighlightOffIcon style={{color:palette.primary.main}}  fontSize='large' /> */}
</div>
<Paper elevation={4} >
    <div className='edit-user-main-box' >
    <div className='inner-page-heading-box' >
        <IconButton sx={{color:'black'}}  onClick={()=>navigate(-1)} >
        <Iconify icon="material-symbols:arrow-back-rounded" />
        </IconButton>
     <div>
     <h2>View or Edit App User</h2>
     <p>  View & edit app users necessary information from here</p>
     </div>
      </div>
        {/* <h2  >
       View or Edit App User
        </h2>
        <p>  View & edit app users necessary information from here</p> */}
    <div className='user-details-inner-box' >
    <form onSubmit={handleSubmitUserDetails}>
                    <div className='add-user-form-style' >
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">App ID  </label>
                    <TextField required  disabled fullWidth className='product_form_input' id="outlined-basic" name="app_id"  onChange={(e)=>setUserData(prev=>({...prev,app_id:e.target.value}))} value={userData?.app_id} placeholder="App ID " variant="outlined" />
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">App Name  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="app_name"  onChange={(e)=>setUserData(prev=>({...prev,app_name:e.target.value}))} value={userData?.app_name} placeholder="Customer Name " variant="outlined" />
                    </div>
                    </div>
                    <div className='add-user-form-style' >
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor=""> Phone Number  </label>
                    <TextField required fullWidth type='number' className='product_form_input' id="outlined-basic" name="phone_number" onChange={(e)=>setUserData(prev=>({...prev,phone_number:e.target.value}))} value={userData?.phone_number}  placeholder="Phone Number" variant="outlined" />
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor=""> User Email </label>
                    <TextField  type='email' fullWidth className='product_form_input' id="outlined-basic" name="email" onChange={(e)=>setUserData(prev=>({...prev,email:e.target.value}))} value={userData?.email} placeholder="Customer Email" variant="outlined" />
                    </div>
                    
                    </div>
                   
                    <div className='add-user-form-style' >
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor=""> App Type </label>
                    <TextField
                        labelId="demo-select-small"
                        
                        fullWidth
                        InputProps={{startAdornment: <InputAdornment position="start"> <CategoryIcon sx={{fontSize:20}}  /></InputAdornment>,}}
                        value={userData?.app_type}
                        onChange={(e)=>setUserData(prev=>({...prev,app_type:e.target.value}))}
                        id="demo-simple-select"
                        className='product_form_input'
                        style={{textTransform:'capitalize'}}
                        select
                        >
                        {store_type?.map((value,index)=>(
                        <MenuItem key={index} style={{textTransform:'capitalize'}}  value={value} >{value}</MenuItem>
                        ))}
                     </TextField>
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor=""> App Color </label>
                    <TextField
                        labelId="demo-select-small"
                        fullWidth
                        InputProps={{startAdornment: <InputAdornment position="start"> <ColorLensIcon sx={{fontSize:20}}  /></InputAdornment>,}}
                        value={userData?.app_color}
                        onChange={(e)=>setUserData(prev=>({...prev,app_color:e.target.value}))}
                        id="demo-simple-select"
                        className='product_form_input'
                        style={{textTransform:'capitalize'}}
                        select
                        >
                            {availablecolors?.map((value,index)=>(
                                <MenuItem key={index}  value={value} >
                                    <div className='choose-color-style' >
                                    <span style={{backgroundColor:`${value}`,padding:'11px 24px',borderRadius:3,marginRight:5,border:'1px solid gray'}} ></span>
                                    <p key={index} style={{textTransform:'capitalize'}} value={value}>{value}</p>
                                    </div>
                                </MenuItem>

                            ))}
                            </TextField>
                    </div>
                    </div>
                    <div className='add-user-form-style' >
                    <div style={{width:'100%'}} className='add_product_label_input' >
   <FormControl fullWidth  >
   <label htmlFor="">App Status  </label>
      <TextField
        labelId="demo-select-small"
        id="demo-select-small"
        select
        value={userData?.app_published}
        onChange={(e)=>setUserData(prev=>({...prev,app_published:e.target.value}))}
      >
        
        <MenuItem value={true}>Published</MenuItem>
        <MenuItem value={false}>Not Published</MenuItem>
      </TextField>
      </FormControl>
   </div>
   <div style={{width:'100%'}} className='add_product_label_input' >
   <FormControl fullWidth  >
   <label htmlFor="">App Dashboard Status  </label>
      <TextField
        labelId="demo-select-small"
        id="demo-select-small"
        select
        value={userData?.app_dashboard_status}
        onChange={(e)=>setUserData(prev=>({...prev,app_dashboard_status:e.target.value}))}
      >
        
        <MenuItem value={true}>Published</MenuItem>
        <MenuItem value={false}>Not Published</MenuItem>
      </TextField>
      </FormControl>
   </div>
   <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">App Dashboard Link  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="app_dashboard_link"  onChange={(e)=>setUserData(prev=>({...prev,app_dashboard_link:e.target.value}))} value={userData?.app_dashboard_link} placeholder="App Dashboard Link " variant="outlined" />
                    </div>
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">User Database URI  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="db_uri"  onChange={(e)=>setUserData(prev=>({...prev,db_uri:e.target.value}))} value={userData?.db_uri} placeholder="App Dashboard Link " variant="outlined" />
                    </div>
  <div className='add-user-form-style' >
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor=""> Dashboard User Email  </label>
                    <TextField  fullWidth  className='product_form_input' id="outlined-basic" name="dashboard_user_email" onChange={(e)=>setUserData(prev=>({...prev,dashboard_user_email:e.target.value}))} value={userData?.dashboard_user_email}  placeholder="Dashboard User Email" variant="outlined" />
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor=""> Dashboard User Password </label>
                    <TextField   fullWidth className='product_form_input' id="outlined-basic"  onChange={(e)=>setUserData(prev=>({...prev,dashboard_user_password:e.target.value}))} value={userData?.dashboard_user_password} placeholder="Dashboard User Password" variant="outlined" />
                    </div>
                    
                    </div>
                    <div className='add-user-form-style' >
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor=""> App Signing SHA-1 </label>
                    <TextField   fullWidth className='product_form_input' id="outlined-basic"  onChange={(e)=>setUserData(prev=>({...prev,dashboard_user_password:e.target.value}))} value={userData?.dashboard_user_password} placeholder="Dashboard User Password" variant="outlined" />
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">App Signing SHA-256 </label>
                    <TextField   fullWidth className='product_form_input' id="outlined-basic"  onChange={(e)=>setUserData(prev=>({...prev,dashboard_user_password:e.target.value}))} value={userData?.dashboard_user_password} placeholder="Dashboard User Password" variant="outlined" />
                    </div>
                    </div>
                    <div className='add_product_label_input'>
                   
                  </div>
                    <div className='add_product_label_input'>
                    <label htmlFor=""> User Address  </label>
                    <TextField multiline rows={4}  fullWidth className='product_form_input' name='address' onChange={(e)=>setUserData(prev=>({...prev,address:e.target.value}))} value={userData?.address} id="outlined-basic" placeholder="User Address " variant="outlined" />
                    </div>
                    <div style={{paddingTop:20}} >

<Button onClick={()=>navigate(-1)}  variant='text' style={{marginRight:"10px"}}  startIcon={<Iconify icon="material-symbols:line-start-arrow-notch-rounded" />} >Back  </Button>

 <Button   variant='contained' type='submit' style={{padding:"6px 30px"}} startIcon={<Iconify icon="material-symbols:check-circle" />} > Save Changes </Button>
</div>
</form>
      
<div className='user-profile-detail-box' >
      <div className='logo-box-join-date-box' > 
            <div className='logo-img-box' >
              <div className='close-download-icon-box' >
              {/* <Iconify className='download-close-icon' style={{color:'#DE040C'}} icon="material-symbols:close" /> */}
            <a href={userData?.app_logo?.image_url} target='_blank' >
             <Iconify   className='download-close-icon'   style={{color:palette.primary.main}}  icon="fluent-mdl2:navigate-external-inline" />
            </a>
              </div>
            <img
                className=""
                alt="product"
                src={userData?.app_logo?.image_url  ? userData?.app_logo?.image_url  : imageImport.noImage } />
              <div style={{textAlign:'center'}} >
              <Button variant="text" style={{marginTop:5}} component="label" startIcon={<Iconify icon="akar-icons:edit" />}>
               Change
                      <input hidden accept="image/*" onChange={(e)=>handleFileUpload(e)} multiple type="file" />
                  </Button>
              </div>
              
            </div>
            <div className='joining-credit-box'  >
              <p>Joining Date : {convertDate(userData?.joining_date) }</p> 
              <p>Products Count : {userData?.products_count}</p> 
              <p>Users Count : {userData?.users_count}</p> 
              <p>Plan Purchased Count : {userData?.plan_purchased_count}</p> 
              <p>Publish Count : {userData?.app_published_count}</p> 
              <p>Credits : {userData?.resubmittion_count}/{userData?.resubmittion_limit }</p> 
            </div>
      </div>
      <hr  />
      <h3>Plan Details</h3>
      <div className='plan-details-inner-box' > 
        <div className='plan-details-single-box' >
          <p >Plan Name : </p> 
          <p 
          className={userData?.plan_details?.plan_name === 'business' ? 'business-plan' 
          :  userData?.plan_details?.plan_name === 'professional' ?
             'professional-plan'
          :
          userData?.plan_details?.plan_name === 'startup' ?
             'startup-plan'
             :  userData?.plan_details?.plan_name === 'free' ?
             'free-plan' :''
          }           
          >{ userData?.plan_details?.plan_name}</p> 
          </div>
        <div className='plan-details-single-box' >
          <p >Plan Duration : </p> 
          <p >{ userData?.plan_details?.duration?.name}</p> 
          </div>
        <div className='plan-details-single-box' >
          <p >Purchase Date : </p> 
          <p >{ userData?.plan_details?.purchased_date}</p> 
          </div>
        <div className='plan-details-single-box' >
          <p >Renew Date : </p> 
          <p >{ userData?.plan_details?.renew_date}</p> 
          </div>
        <div className='plan-details-price-box' >
          <p >Total Price With GST : </p> 
          <p style={{color:palette.primary.main}} >₹{ userData?.plan_details?.total_price_with_gst}</p> 
          </div>
      </div>
      {(userData?.app_signing?.app_sha_1 && userData?.app_signing?.app_sha_256 ) &&
        <div>
        <hr style={{marginTop:30}} />
      <h4 style={{paddingBottom:10}} >App Signing Details</h4>
      <p className='app_id_and_copy_box' > 
      <span className='id_styles' ><strong>App SHA-1 Key :</strong> {userData?.app_signing?.app_sha_1}</span>
        <span onClick={()=>copyContentFunc(userData?.app_signing?.app_sha_1)} className='copy_app_id_style' style={{color:palette.primary.main}} ><Iconify icon="mingcute:copy-fill" /></span>
      </p>
      <p className='app_id_and_copy_box' >
      <span className='id_styles' ><strong>App SHA-256 Key :</strong> {userData?.app_signing?.app_sha_256}</span>
        <span onClick={()=>copyContentFunc(userData?.app_signing?.app_sha_256)} className='copy_app_id_style' style={{color:palette.primary.main}} ><Iconify icon="mingcute:copy-fill" /></span>
      </p>
      </div>
      }

    
      </div>
    

    </div>
  

   </div>
   </Paper>

   </>
  )
}

export default EditUserPage