import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton, TextField } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { editable_config } from 'src/editable_config';
import axios from 'axios';
import { toast } from 'react-toastify';



export default function EditAnnouncementModal(props) {
  const [ announcementData, setAnnouncementData ] = React.useState()
  console.log("props.announcement_dataprops.announcement_dataprops.announcement_data",props.announcement_data)
  console.log("announcementData.announcementData.announcementData.announcementData",announcementData)

  React.useEffect(()=>(
    setAnnouncementData(props.announcement_data)
  ),[props.announcement_data])
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: props.modalWidth,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius:"10px 10px ",
    boxShadow: 24,
    p: 4,
  };

  const handleSubmitUserDetails = async (e)=>{
    e.preventDefault()
    await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/edit/app/announcement/by/id/${announcementData?._id}`,{...announcementData}, {headers: {
      'Authorization': `token ${editable_config.FRONTEND_VALIDATOR}`,
    },withCredentials:true})
    .then(res=>{
      console.log(res?.data)
      toast.success("Changes Saved!!")
      setAnnouncementData((prev=>({
        title:'',
        readmore_link:'',
        message:'',
        image_url:'',
      })))
      props.close()
    })
    .catch(err=>{
      console.log(err)
      toast.error("Something Went Wrong!!")
    })

  }
 

  return (
    <div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        disableAutoFocus
        open={props.open}
        onClose={props.close}
        closeAfterTransition
        // disableEscapeKeyDown
        disableEnforceFocus
        // onBackdropClick='false'
        // BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
          <Box sx={style}>
          <IconButton onClick={props.close} style={{color:'text.secondary'}} className='close-upgrade-icon' >
          <Iconify icon="material-symbols:close" />
          </IconButton>
          <div>
     <h2>Edit Announcement</h2>
     <p>  Edit app announcements necessary information from here</p>
     </div>
              <div>
              <form onSubmit={handleSubmitUserDetails} >
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">Accouncement Title  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="title"  onChange={(e)=>setAnnouncementData(prev=>({...prev,title:e.target.value}))} value={announcementData?.title} placeholder="Enter Announcement Title " variant="outlined" />
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">Accouncement Image Uri  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="image_url"  onChange={(e)=>setAnnouncementData(prev=>({...prev,image_url:e.target.value}))} value={announcementData?.image_url} placeholder="Enter Image URI " variant="outlined" />
                    </div>
                    <div style={{width:'100%'}} className='add_product_label_input'>
                    <label htmlFor="">Read More Link  </label>
                    <TextField  fullWidth className='product_form_input' id="outlined-basic" name="readmore_link"  onChange={(e)=>setAnnouncementData(prev=>({...prev,readmore_link:e.target.value}))} value={announcementData?.readmore_link} placeholder="Enter Read More Link " variant="outlined" />
                    </div>
                    <div className='add_product_label_input'>
                    <label htmlFor=""> Accouncement Message  </label>
                    <TextField multiline rows={4}  fullWidth className='product_form_input' name='message' onChange={(e)=>setAnnouncementData(prev=>({...prev,message:e.target.value}))} value={announcementData?.message} id="outlined-basic" placeholder="Enter Announcement Message " variant="outlined" />
                    </div>
                    <div style={{paddingTop:20}} >

<Button onClick={props.close}  variant='text' style={{marginRight:"10px"}}   >Discard  </Button>

 <Button   variant='contained' type='submit' style={{padding:"6px 30px"}} startIcon={<Iconify icon="material-symbols:check-circle" />} > Save Changes </Button>
</div>
</form>
      
              </div>
          </Box>
      </Modal>
    </div>
  );
}