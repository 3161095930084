// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  // {
  //   title: 'orders',
  //   path: '/dashboard/orders',
  //   icon: getIcon('bxs:box'),
  // },
  // {
  //   title: 'products',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  // {
  //   title: 'Categories',
  //   path: '/dashboard/categories',
  //   icon: getIcon('material-symbols:category-rounded'),
  // },
  // {
  //   title: 'banners',
  //   path: '/dashboard/banners',
  //   icon: getIcon('eva:image-fill'),
  // },
  {
    title: 'Users',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Publish Requests',
    path: '/dashboard/publish-requests',
    icon: getIcon('bxl:play-store'),
  },
  {
    title: 'Payments',
    path: '/dashboard/payments',
    icon: getIcon('ic:baseline-currency-rupee'),
  },
 {
    title: 'Announcemnts',
    path: '/dashboard/announcemnts',
    icon: getIcon('heroicons-outline:speakerphone'),
  },


  // {
  //   title: 'Settings',
  //   path: '/dashboard/settings',
  //   icon: getIcon('ant-design:setting-filled'),
  // },

  
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: getIcon('eva:lock-fill'),
  // },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  

  
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
