export const editable_config = {
    Admin_Name : "Adiogent | Admin Panel",
    Logo : require("../src/assests/adiogentlogo.png"),
    // InoviceLogo:"https://ssa-admin.blackhatcode.in/static/media/ssa_logo.3758b4a24cc9716699dc.png",
    // InvoiceCompanyName:"Online E-Commerce Store",
    // InvoiceAddress:"Main Market 2nd Pusta Sonia Vihar",
    // InvoiceZip:"110090",
    // InvoiceCity:"Delhi",
    // InvoicePhoneNumber:"9999091290, 8468872233",
   
   FRONTEND_VALIDATOR:'iwerbxinuwfxawuxdubfjsaknbfkjsgfabewubinkjbmayankandsanjeevbdhiyahingeuinhrcuiashuichraweiubrcuiwaenhfiucnbwaeiufnciuwefbnciusbonficsnabifxkmasfacfnbsakxfbmascaca',
   Main_Color : "#0072FF" , // 007FFF #28A75A
    Hover_Color:"#0061da",  // #06973f
    UpgradePlanLink:'https://blackhatcode.in/',
    storeCategory:[
        'garments store',
        'mens fashion',
        'womens fashion',
        'womens innerwear',
        'jewellery',
        'footwear',
        'beauty & care',
        'furniture',
        'home decors',
        'electronics',
        'fruits & veggies',
        'stationery',
        'baby & care',
        'gym accessories',
        'grocery',
        'sports',
        'healthcare',
        'other',
    ]
    
}